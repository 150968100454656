<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ $t('register') }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-row type="flex" justify="space-around" align="middle" :style="{ margin:'80px auto'}">
      <a-col :span="16" :style="{ textAlign:'center',marginBottom: '30px'}">
        <a-typography-title :level="4">{{ $t('register_welcome') }}</a-typography-title>
        <a-typography-text>{{ $t('register_welcome_desc') }}</a-typography-text>
      </a-col>
      <a-col :span="16" :style="{ marginBottom: '30px'}">
        <a-steps :current="stepIndex" labelPlacement="vertical" size="small">
          <a-step :title="$t('register_info')"/>
          <a-step :title="$t('email_authentication')"/>
          <a-step :title="$t('info_verification')"/>
        </a-steps>
      </a-col>
      <a-col :span="10" v-if="stepIndex===0">
      </a-col>
      <a-col :span="10" v-if="stepIndex===1">
        <template v-if="initLoading">
          <a-spin :spinning="initLoading" size="large">
            <div style="width: 100%;height: 300px"></div>
          </a-spin>
        </template>
        <template v-else>
          <a-result status="error"
                    :title="$t('register_email_confirm_error')">
            <template #extra>
              <a-button @click="$router.push({name:'home'})">{{ $ta('back|home', 'p') }}</a-button>
            </template>
          </a-result>
        </template>
      </a-col>
      <a-col :span="16" v-if="stepIndex===2">
        <template v-if="initLoading">
          <a-spin :spinning="initLoading" size="large">
            <div style="width: 100%;height: 300px"></div>
          </a-spin>
        </template>
        <template v-else>
          <a-result
              status="success"
              :title="$t('register_success')"
              :sub-title="$t('register_welcome_desc')">
            <template #extra>
              <a-button @click="$router.push({name:'home'})">{{ $ta('back|home', 'p') }}</a-button>
            </template>
          </a-result>
        </template>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {confirmEmail} from "@/services/account";
import {isEmpty} from "@/utils/util";

export default {
  name: "ConfirmEmail",
  data() {
    const code = this.$route?.query?.Code
    if(isEmpty(code)){
      this.stepIndex = 1
      const _this = this
      setTimeout(function(){
        _this.initLoading = false
      },500)
    } else {
      confirmEmail(code).then(res => {
        this.initLoading = false
        const resData = res.data
        if (resData.Success) {
          this.stepIndex = 2
        } else {
          this.stepIndex = 1
        }
      }).catch(error => {
        console.log(error)
      })
    }

    return {
      stepIndex: 1,
      initLoading: true,
    }
  },
  methods: {
    confirmEmail
  }
}
</script>

<style scoped>

</style>